import {
  initAccessibe,
  initDataDog,
  initSegment,
  initSentry,
  isTestSession,
} from '@renofi/analytics';

export default async ({config}) => {
  const {DEVELOPMENT, PRODUCTION} = config;

  initDataDog();

  if (isTestSession()) {
    return;
  }

  if (!DEVELOPMENT) {
    initSegment();
  }

  if (PRODUCTION) {
    initSentry();
    initAccessibe();
  }
};
