import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {GET_USER} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useCurrentUser({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_USER,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const user = propOr(null, 'user', data);

  return {fetch, user, data, loading, error, refetching};
}
